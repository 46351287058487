import React, { Component } from 'react'

import { Modal, Button, Form, Input } from 'antd'

import UserService from '../../services/UserService'

class SetCredit extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  state = {
    visible: this.props.visible,
    data: this.props.data,
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    console.log(e)
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    this.props.onClose(true)
    this.props.onReload(true)
  }

  handleConfirm = async (e) => {
    let data = {}
    // if (this.props.userType === 'User') {
    data = {
      userId: e.user_id,
      credit: e.credit,
    }
    // }

    await UserService.setCredit(data)
      .then((res) => {
        console.log('set credit ', res.data)
      })
      .catch((e) => {
        console.log(e)
      })

    this.setState({
      visibleCredit: false,
    })
    this.handleCancel()
  }

  handleCancelConfirm = () => {
    this.setState({
      visibleCredit: false,
    })
  }

  render() {
    return (
      <>
        <Modal
          destroyOnClose={true}
          title="Credit"
          visible={this.props.visible}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <Form
            onFinish={this.handleConfirm}
            name="creditForm"
            initialValues={{
              // eslint-disable-next-line
              ["user_id"]: this.props.data.user_id,
            }}>
            <Form.Item name="credit" label="เครดิต">
              <Input />
            </Form.Item>
            <Form.Item name="user_id" hidden>
              <Input defaultValue={this.props.data.user_id} />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ float: "right" }}
                key="submit"
                type="primary"
                htmlType="submit"
              // onClick={() => this.handleConfirm(this.props.data)}
              >
                ยืนยัน
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}

export default SetCredit
