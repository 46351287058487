import React, { Component } from "react";

import { Menu } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import UserService from "../../services/UserService";

const admin = localStorage.getItem("user");
const dataAdmin = JSON.parse(admin);
class Navbar extends Component {
  logOut = () => {
    UserService.logout()
    window.location.href = '/'
  }

  render() {
    console.log('dataAdmin')
    return (
      <>
        <div className="logo"></div>
        <Menu
          className="topnav"
          style={{ float: "right" }}
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["/"]}
        >
          <Menu.Item key="1" disabled>
            <UserOutlined />
            {dataAdmin.admin_id}
          </Menu.Item>
          <Menu.Item key="2">
            <a href="/" rel="noopener noreferrer">
              Home
            </a>
          </Menu.Item>
          {/* <Menu.Item key="2">
            <a href="/about" rel="noopener noreferrer">
              About
            </a>
          </Menu.Item>
          <Menu.Item key="3">
            <a href="/details" rel="noopener noreferrer">
              Details
            </a>
          </Menu.Item> */}
          <Menu.Item key="4" onClick={() => this.logOut()}>
            Logout
            <LogoutOutlined
              style={{
                fontSize: "20px",
                marginLeft: "10px",
              }}
            />
          </Menu.Item>
        </Menu>
        <style jsx>{``}</style>
      </>
    );
  }
}

export default Navbar;
